<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
<!--      <a-form-model-item label="用户ID" prop="userId" >-->
<!--        <a-input v-model="form.userId" placeholder="请输入用户ID" />-->
<!--      </a-form-model-item>-->

<!--      <a-form-model-item label="项目ID" prop="projectId" >-->
<!--        <a-input v-model="form.projectId" placeholder="请输入项目ID" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="BR描述" prop="brDescription" >-->
<!--        <a-input v-model="form.brDescription" placeholder="请输入内容" type="textarea" allow-clear />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="订单编号" prop="orderNo" >-->
<!--        <a-input v-model="form.orderNo" placeholder="请输入订单编号" />-->
<!--      </a-form-model-item>-->
<!--      1-顾问订单 2-分析师订单 3-推广曝光订单 -->
      <a-form-model-item label="订单类型" prop="orderType" >
        <a-select placeholder="请选择订单类型" v-model="form.orderType">
          <a-select-option :value="1">
            顾问订单
          </a-select-option>
          <a-select-option :value="2">
            分析师订单
          </a-select-option>
          <a-select-option :value="3">
            推广曝光订单
          </a-select-option>
<!--          <a-select-option v-for="(d, index) in orderTypeOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>-->
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="接单人ID" prop="projectId" >
        <a-input v-model="form.resourceId" placeholder="接单人ID" />
      </a-form-model-item>

<!--      <a-form-model-item label="付款编号" prop="paymentNo" >-->
<!--        <a-input v-model="form.paymentNo" placeholder="请输入付款编号" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="外部交易号" prop="outTradeNo" >-->
<!--        <a-input v-model="form.outTradeNo" placeholder="请输入外部交易号" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="支付类型" prop="payType" >-->
<!--        <a-select placeholder="请选择支付类型" v-model="form.payType">-->
<!--          <a-select-option v-for="(d, index) in payTypeOptions" :key="index" :value="d.value" >{{ d.label }}</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="支付状态" prop="orderStatus" >-->
<!--        <a-radio-group v-model="form.orderStatus" button-style="solid">-->
<!--          <a-radio-button v-for="(d, index) in orderStatusOptions" :key="index" :value="d.value">{{ d.label }}</a-radio-button>-->
<!--        </a-radio-group>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="分析师和顾问提供的文件" prop="serviceDocuments" >-->
<!--        <a-input v-model="form.serviceDocuments" placeholder="请输入内容" type="textarea" allow-clear />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="支付金额" prop="payMoney" >-->
<!--        <a-input v-model="form.payMoney" placeholder="请输入支付金额" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="支付时间" prop="payTime" >-->
<!--        <a-date-picker style="width: 100%" v-model="form.payTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="备注" prop="remark" >-->
<!--        <a-input v-model="form.remark" placeholder="请输入备注" />-->
<!--      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getOrderDream, addOrderDream, updateOrderDream } from '@/api/biz/orderDream'

export default {
  name: 'CreateForm',
  props: {
    orderTypeOptions: {
      type: Array,
      required: true
    },
    payTypeOptions: {
      type: Array,
      required: true
    },
    orderStatusOptions: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        projectId: null,

        brDescription: null,

        orderNo: null,

        orderType: null,

        paymentNo: null,

        resourceId: null,

        outTradeNo: null,

        payType: null,

        orderStatus: 0,

        serviceDocuments: null,

        payMoney: null,

        payTime: null,

        createTime: null,

        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        projectId: null,
        brDescription: null,
        orderNo: null,
        orderType: null,
        paymentNo: null,
        resourceId: null,
        outTradeNo: null,
        payType: null,
        orderStatus: 0,
        serviceDocuments: null,
        payMoney: null,
        payTime: null,
        createTime: null,
        remark: null
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getOrderDream({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateOrderDream(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addOrderDream(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
