import request from '@/utils/request'


// 查询梦之投订单记录列表
export function listOrderDream(query) {
  return request({
    url: '/biz/pay-order-dream/list',
    method: 'get',
    params: query
  })
}

// 查询梦之投订单记录分页
export function pageOrderDream(query) {
  return request({
    url: '/biz/pay-order-dream/page',
    method: 'get',
    params: query
  })
}

// 查询梦之投订单记录详细
export function getOrderDream(data) {
  return request({
    url: '/biz/pay-order-dream/detail',
    method: 'get',
    params: data
  })
}

// 新增梦之投订单记录
export function addOrderDream(data) {
  return request({
    url: '/biz/pay-order-dream/add',
    method: 'post',
    data: data
  })
}

// 修改梦之投订单记录   /biz/pay-order-dream/edit
export function updateOrderDream(data) {
  return request({
    url: '/biz/pay-order-dream/sendOrders',
    method: 'post',
    data: data
  })
}

// 删除梦之投订单记录
export function delOrderDream(data) {
  return request({
    url: '/biz/pay-order-dream/delete',
    method: 'post',
    data: data
  })
}
